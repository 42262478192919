<template>
  <div class="card grid grid-cols-12">
    <div class="col-span-12 md:col-span-5">
      <div class="w-full h-full  p-5">
        <div class="my-3">
          <h1 class="uppercase font-bold flex items-center">
            <span class="text-green-500 text-3xl "
              >{{ currencySymbol }}{{ topupOfferPrice }}</span
            >
          </h1>
          <h3>
            <span class="text-orange-500 text-base font-semibold"
              >{{ ridingDuration }}
              {{
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.card.minutes'
                )
              }}</span
            >
          </h3>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-7">
      <div class="w-full h-full top-border p-5">
        <h2 class="font-semibold text-lg block mb-1 lg:hidden">
          {{ $t('components.subscriptionManagement.addEdit.card.details') }}
        </h2>
        <p class="flex gap-2 text-sm mb-1 ">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate">{{
            $t(
              'components.topupOfferManagement.addEdit.steps.topup.card.freeUnlockCharge'
            )
          }}</span>
        </p>

        <p class="flex gap-2 text-sm mb-1">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{
              $t(
                'components.topupOfferManagement.addEdit.steps.topup.card.useWithin'
              )
            }}
            {{ validityDuration }}
            {{ getValidityDurationUnit }}</span
          >
        </p>
        <p class="flex gap-2 text-sm mb-1">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{
              $t(
                'components.topupOfferManagement.addEdit.steps.topup.card.startAt'
              )
            }}
            {{ topupOfferStartDate }}</span
          >
        </p>
        <p class="flex gap-2 text-sm mb-1">
          <img class="w-3" src="@/assets/icon/offer/pass/mark.svg" alt="mark" />
          <span class="truncate"
            >{{
              $t(
                'components.topupOfferManagement.addEdit.steps.topup.card.expireAt'
              )
            }}
            {{ topupOfferExpiredDate }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopUpOfferCard',
  props: {
    ridingDuration: {
      type: String,
      default: '--',
    },
    validityDuration: {
      type: String,
      default: '--',
    },
    validityDurationUnit: {
      type: Number,
      default: 0,
    },
    currencySymbol: {
      type: String,
      default: '--',
    },
    topupOfferPrice: {
      type: String,
      default: '--',
    },
    topupOfferStartDate: {
      type: String,
      default: '--',
    },
    topupOfferExpiredDate: {
      type: String,
      default: '--',
    },
  },
  computed: {
    getValidityDurationUnit() {
      if (this.validityDurationUnit == 0) {
        if (this.validityDuration > 1) {
          return this.$t(
            'components.topupOfferManagement.addEdit.steps.topup.card.hours'
          )
        } else {
          return this.$t(
            'components.topupOfferManagement.addEdit.steps.topup.card.hour'
          )
        }
      } else {
        if (this.validityDuration > 1) {
          return this.$t(
            'components.topupOfferManagement.addEdit.steps.topup.card.days'
          )
        } else {
          return this.$t(
            'components.topupOfferManagement.addEdit.steps.topup.card.day'
          )
        }
      }
    },
  },
  methods: {},
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.card {
  min-width: 200px;
  height: auto;
  box-shadow: 0 4px 11px 0 #3134692c;
  @apply bg-white rounded-lg;
}

.circle-cut {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 99999px;
  @apply bg-gray-100;
}
.circle-cut.circle-top {
  top: -177px;
}
.circle-cut.circle-bottom {
  bottom: -177px;
}
.divider {
  width: 0.5px;
  height: 100%;
}
.referral-code {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  text-transform: uppercase;
  color: #676773;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.code {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e39;
}
.copy {
  margin-top: 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #da35a1;
  cursor: pointer;
}
.h-122px {
  height: 122px;
}
.w-122px {
  width: 118px;
}
.mt-31px {
  margin-top: 35px;
}

.top-border {
  @media (max-width: 767px) {
    border-top: 1px solid;
    @apply border-gray-200;
  }
}
</style>
