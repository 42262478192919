<template>
  <slide-over-right :id="sorId" editType="pass" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center w-1/2 panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6 mt-2">
      <TopupCard
        :ridingDuration="form.riding_time_seconds"
        :validityDuration="form.validity_duration"
        :validityDurationUnit="form.validity_duration_unit"
        :currencySymbol="currencySymbol"
        :topupOfferPrice="form.price"
        :topupOfferStartDate="getTopupStartedDate"
        :topupOfferExpiredDate="getTopupExpiredDate"
      />
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
          class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1 mt-3"
        >
          <div>
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.fleetCurrency'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.fleetCurrency'
                )
              "
              :placeholder="getFleetCurrencyPlaceholder"
              :disabled="getFleetCurrencyDisableState"
              :options="getCurrencyOptions"
              :isInfoEnabled="false"
              hide-search-box
              rules="required"
              v-model="form.currency"
            />
          </div>
          <div>
            <AppInput
              :key="`fleet-${counter}`"
              type="richselect"
              v-model="form.assigned_fleets"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.assignedFleet'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.assignedFleet'
                )
              "
              :placeholder="getAssignedFleetPlaceholder"
              :disabled="getAssignedFleetDisabledState"
              :options="getFleetOptions"
              :isInfoEnabled="false"
              hide-search-box
              rules="required"
              multiple
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <AppInput
              type="richselect"
              v-model="form.validity_duration_unit"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.validityUnit'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.validityUnit'
                )
              "
              :placeholder="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.placeholder.validityDurationUnit'
                )
              "
              :options="[
                { text: 'Hour', value: 0 },
                { text: 'Day', value: 1 },
              ]"
              :isInfoEnabled="false"
              hide-search-box
              rules="required"
            />

            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.validityDuration'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.validityDuration'
                )
              "
              placeholder="e.g. 10"
              v-model="form.validity_duration"
              :haveSuggestion="false"
              step="1"
              min="1"
              :haveUnitText="true"
              :unitText="getValidityDurationUnitText"
              :isInfoEnabled="false"
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.price'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.price'
                )
              "
              placeholder="e.g. 10"
              v-model="form.price"
              :haveSuggestion="false"
              step="0.1"
              min="0"
              :haveUnitText="true"
              :unitText="getCurrencySymbol"
              :isInfoEnabled="false"
            />
            <AppInput
              type="number"
              rules="required"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.ridingTime'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.ridingTime'
                )
              "
              placeholder="e.g. 10"
              v-model="form.riding_time_seconds"
              :haveSuggestion="false"
              step="1"
              min="1"
              :haveUnitText="true"
              unitText="Minute"
              :isInfoEnabled="false"
            />
          </div>
          <div class="grid grid-cols-2 gap-4 mt-2">
            <AppInput
              type="date"
              rules="required"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.startDate'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.startDate'
                )
              "
              v-model="form.start_date"
              :haveSuggestion="false"
              :isInfoEnabled="false"
              :min="minStartDate"
            />
            <AppInput
              type="date"
              rules="required"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.expireDate'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.expireDate'
                )
              "
              v-model="form.end_date"
              :haveSuggestion="false"
              :isInfoEnabled="false"
              :min="minEndDate"
            />
          </div>
          <div>
            <AppInput
              type="richselect"
              v-model="form.is_active"
              :name="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.status'
                )
              "
              :label="
                $t(
                  'components.topupOfferManagement.addEdit.steps.topup.title.status'
                )
              "
              :options="[
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
              ]"
              :isInfoEnabled="false"
              hide-search-box
              rules="required"
            />
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">{{
            $t('components.stepNavigation.cancel')
          }}</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">{{
          actionButtonText
        }}</t-button>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { TopupOfferConfig } from '@/config/TopupOfferConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import dayjs from 'dayjs'
import TopupCard from '@/components/cards/TopupCard.vue'
import { getDate } from '@/utils'
import { useEndpoints } from '@/composables'
export default {
  name: 'AddEditTopupOffer',
  components: {
    SlideOverRight,
    AnchorButton,
    SlideOverRightTrigger,
    TopupCard,
  },
  data() {
    return {
      sorId: TopupOfferConfig.events.sorId,
      isLoading: false,
      fleetCurrencyIsLoading: false,
      fleetIsLoading: false,
      form: {},
      statusOptions: [
        {
          value: false,
          text: 'Inactive',
        },
        {
          value: true,
          text: 'Active',
        },
      ],
      allCurrencies: [],
      allFleets: [],
      counter: 0,
      currencySymbol: '--',
      item: {},
      primaryKey: null,
      minStartDate: '',
      minEndDate: '',
    }
  },
  async created() {
    this.fleetCurrencyIsLoading = true
    this.minDatecalculation()
    await this.$http
      .get(useEndpoints.dropdown.organizationCurrencies())
      .then((res) => {
        this.allCurrencies = res.data
      })
      .catch((err) => console.log('currencyListErr', { err }))
      .finally(() => {
        this.fleetCurrencyIsLoading = false
      })
  },
  mounted() {
    EventBus.$on(
      TopupOfferConfig.events.editingData,
      ({ item = {}, primaryKey = null }) => {
        this.counter = 0
        console.log('mounted-test', item, primaryKey)
        this.item = { ...item }
        this.primaryKey = primaryKey
        this.form = {}

        if (item) {
          //edit
          this.form = { ...item }
        } else {
          //add
          this.init()
        }
      }
    )
  },
  computed: {
    titleText() {
      return this.isEditing
        ? this.$t('components.topupOfferManagement.addEdit.headline.update')
        : this.$t('components.topupOfferManagement.addEdit.headline.add')
    },
    isEditing: function() {
      return !!this.primaryKey
    },
    actionButtonText: function() {
      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.save')
    },
    getFleetCurrencyPlaceholder() {
      if (this.fleetCurrencyIsLoading)
        return this.$t(
          'components.topupOfferManagement.addEdit.steps.topup.placeholder.fleetCurrency.loading'
        )
      return this.$t(
        'components.topupOfferManagement.addEdit.steps.topup.placeholder.fleetCurrency.selectFleetCurrency'
      )
    },
    getFleetCurrencyDisableState() {
      if (this.fleetCurrencyIsLoading) return true
      return false
    },
    getAssignedFleetPlaceholder() {
      if (!this.form.currency)
        return this.$t(
          'components.topupOfferManagement.addEdit.steps.topup.placeholder.assignedFleet.selectFleetCurrencyFirst'
        )
      if (this.fleetIsLoading)
        return this.$t(
          'components.topupOfferManagement.addEdit.steps.topup.placeholder.assignedFleet.loading'
        )
      if (this.fleetIsLoading === false && this.getFleetOptions.length === 0)
        return this.$t(
          'components.topupOfferManagement.addEdit.steps.topup.placeholder.assignedFleet.noFleetFound'
        )
      return this.$t(
        'components.topupOfferManagement.addEdit.steps.topup.placeholder.assignedFleet.selectFleets'
      )
    },
    getAssignedFleetDisabledState() {
      if (!this.form.currency) return true
      if (this.fleetIsLoading) return true
      if (this.fleetIsLoading === false && this.getFleetOptions.length === 0)
        return true
      return false
    },
    getFleetOptions() {
      return this.allFleets.map((fleet) => ({
        text: `${fleet.name} (${fleet.country.code3})`,
        value: fleet.id,
      }))
    },
    getCurrencyOptions() {
      return this.allCurrencies.map((ci) => {
        return { text: `${ci.name}(${ci.symbol})`, value: ci.id }
      })
    },
    getCurrencySymbol() {
      return this.currencySymbol
    },
    getValidityDurationUnitText() {
      let unitText = ''
      if (this.form.validity_duration_unit === 0) {
        unitText = 'Hour'
      }
      if (this.form.validity_duration_unit === 1) {
        unitText = 'Day'
      }
      return unitText
    },
    getTopupStartedDate() {
      return this.getDate(this.form.start_date)
    },
    getTopupExpiredDate() {
      return this.getDate(this.form.end_date)
    },
  },
  watch: {
    'form.currency': {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const filteredData = this.allCurrencies.find(
            (item) => item.id === val
          )
          console.log('form-currency', val, filteredData)
          this.currencySymbol = filteredData.symbol
          this.fleetIsLoading = true
          this.$http
            .get(`/dashboard/fleets/?dropdown&currency=${val}`)
            .then((res) => {
              this.allFleets = res.data.data

              setTimeout(() => {
                this.counter++
              }, 1000)

              if (this.isEditing && this.item.currency === val) return
              if (this.isEditing && this.item.currency !== val) {
                this.form.assigned_fleets = []
              }
            })

            .catch((err) => console.log('fleet-Err', { err }))
            .finally(() => {
              this.fleetIsLoading = false
            })
        }
      },
    },
  },
  methods: {
    getDate,
    init() {
      this.form = {
        currency: '',
        assigned_fleets: [],
        validity_duration_unit: 0,
        validity_duration: '',
        price: '',
        riding_time_seconds: '',
        start_date: '',
        end_date: '',
        is_active: false,
      }
    },
    minDatecalculation() {
      this.minStartDate = dayjs().format('YYYY-MM-DD')
      this.minEndDate = dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    },
    submit() {
      this.$refs.submitButton.click()
    },
    showModal() {
      dispatchEvent(new Event(TopupOfferConfig.events.sorToggle))
    },

    onChangeStartDate(event) {
      this.start_date = event
      this.getValidateDays()
    },
    onChangeExpireDate(event) {
      this.end_date = event
      this.getValidateDays()
    },
    getValidateDays() {
      if (this.start_date != '' && this.end_date != '') {
        let date1 = new Date(this.start_date)
        let date2 = new Date(this.end_date)

        var Difference_In_Time = date2.getTime() - date1.getTime()
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
        if (Difference_In_Days >= 0) {
          this.validate_day = Difference_In_Days + ' days'
        } else {
          this.start_date = ''
          this.end_date = ''
        }
      }
    },

    async confirmSubmit() {
      this.isLoading = true
      const formProxy = { ...this.form }

      if (this.form.riding_time_seconds) {
        formProxy.riding_time_seconds =
          parseInt(this.form.riding_time_seconds) * 60
      }
      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })
      formProxy.start_date = formProxy.start_date + 'T00:00:00'
      formProxy.end_date = formProxy.end_date + 'T00:00:00'
      console.log('confirmSubmit', formProxy)

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? TopupOfferConfig.api.update(this.primaryKey)
        : TopupOfferConfig.api.create

      const formData = new FormData()
      // todo: make a form data converter function
      for (const key in formProxy) {
        if (Array.isArray(formProxy[key])) {
          formProxy[key].forEach((el) => {
            formData.append(key, el)
          })
          continue
        }
        formData.append(key, formProxy[key])
      }
      const message = this.isEditing
        ? 'Topup offer updated successfully'
        : 'Topup offer added successfully'

      await this.$http({ method, url, data: formData })
        .then((res) => {
          console.log(res)
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          })
          dispatchEvent(new Event(TopupOfferConfig.events.sorToggle))
          this.$emit('refresh')
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: err.response.data?.message || 'Failed to update',
            },
            10000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
